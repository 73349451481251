<template lang="pug">
  .changeOrder
    project-facade(v-if="$route.params.id" active-tab="changeOrder", :project-id="$route.params.id")
    change-order-form(
      v-model='changeOrder',
      submit-label='Add change order',
      :loading='$apollo.loading',
      :saving='saving',
      @save='createChangeOrder',
      @cancel='cancelCreation')
</template>

<script>
import createChangeOrderMutation from "@/graphql/mutations/finance/change_orders/CreateChangeOrder.gql";
import createChangeOrderFilter from "@/graphql/mutations/filters/CreateChangeOrderFilter";
import ProjectQuery from "@/graphql/queries/finance/Project.gql";
import mutate from "@/components/utils/Mutator";
import ChangeOrderForm from "./Form.vue";
import { formatDateApi } from "@/helpers/DateHelpers";
import ProjectFacade from "@/components/finance/project_facade/ProjectFacade.vue";

export default {
  apollo: {
    project: {
      query: ProjectQuery,
      variables() {
        return { id: this.$route.params.id };
      },
      skip() {
        return !this.$route.params.id;
      }
    }
  },
  components: {
    ChangeOrderForm,
    ProjectFacade
  },
  data() {
    return {
      saving: false,
      project: null,
      changeOrder: {
        status: "DRAFT",
        currency: this.$store.state.defaultCurrency,
        lineItems: [{ key: 0 }, { key: 1 }, { key: 2 }]
      }
    };
  },
  watch: {
    project(project) {
      this.$set(this.changeOrder, "project", project);
      this.$set(this.changeOrder, "currency", project.currency);
    }
  },
  methods: {
    createChangeOrder() {
      const vm = this;
      this.saving = true;
      const input = { ...createChangeOrderFilter(this.changeOrder) };
      input.approvalDeadline = formatDateApi(input.approvalDeadline);

      return mutate(this, {
        mutation: createChangeOrderMutation,
        variables: { input }
      })
        .then(data => {
          window.location.assign(
            `/projects/${this.changeOrder.project.id}/change_orders/${data.data.createChangeOrder.changeOrder.id}`
          );
        })
        .then(() => {
          vm.saving = false;
        });
    },
    cancelCreation() {
      if (this.$route.params.id) {
        window.location.assign(
          `/projects/${this.$route.params.id}/#change_orders`
        );
      } else {
        window.location.assign("/finances/#change_orders");
      }
    }
  }
};
</script>
