import reject from "ramda/src/reject";
import isEmpty from "ramda/src/isEmpty";
import isNil from "ramda/src/isNil";
import lineItemFilter from "./MarkupLineItemFilter";

export default changeOrder => ({
  name: changeOrder.name,
  project: {
    id: changeOrder.project.id
  },
  number: changeOrder.number,
  description: changeOrder.description,
  approvalDeadline: changeOrder.approvalDeadline,
  lineItems: reject(
    li => isEmpty(li.item.id) || isNil(li.item.id),
    changeOrder.lineItems.map(li => lineItemFilter(li))
  )
});
