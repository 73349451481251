<template lang="pug">
  page-layout(:title='$route.name', :loading='loading || !changeOrder')
    finance-form(
      :structure='formStructure',
      v-model='changeOrder',
      :submit-label='submitLabel',
      modelName='changeOrder',
      @save='saveChangeOrder',
      @cancel='$emit("cancel")',
      @input='$emit("input", $event)'
      :v='$v',
      :loading='loading',
      :saving='saving')
</template>

<script>
import {
  helpers,
  required,
  requiredIf,
  requiredUnless,
  decimal
} from "vuelidate/lib/validators";
import isNil from "ramda/src/isNil";
import isEmpty from "ramda/src/isEmpty";
import clone from "ramda/src/clone";
import contains from "ramda/src/contains";
import FinanceForm from "@/views/finances/Form.vue";
import PageLayout from "@/views/common/PageLayout.vue";
import scrollToError from "@/components/utils/ScrollToError";

const isNilOrEmpty = nestedModel =>
  isNil(nestedModel.item) || isEmpty(nestedModel.item);
const lineItemRequiredValidation = requiredUnless(isNilOrEmpty);

export default {
  components: {
    FinanceForm,
    PageLayout
  },
  props: {
    value: Object,
    loading: Boolean,
    saving: Boolean,
    submitLabel: String
  },
  data() {
    return {
      changeOrder: clone(this.value),
      formStructure: {
        resourceName: "Change order",
        keyFields: [
          {
            label: "Change order name",
            name: "name",
            type: "input",
            placeholder: "Name this change order",
            required: true,
            help: "finance.changeOrders.form.name"
          },
          {
            label: "Project",
            name: "project",
            type: "project",
            placeholder: "Select a project",
            required: true
          }
        ],
        detailFields: [
          { label: "Number", name: "number", type: "sequence" },
          {
            label: "Approval deadline",
            name: "approvalDeadline",
            type: "date"
          }
        ],
        descriptionField: {
          placeholder: "Describe the reason for this change order...",
          name: "description"
        },
        lineItems: {
          type: "markup",
          task: false,
          description: true
        }
      }
    };
  },
  validations: {
    changeOrder: {
      name: {
        required
      },
      project: {
        required
      },
      status: {
        required
      },
      lineItems: {
        $each: {
          quantity: {
            required: lineItemRequiredValidation,
            decimal
          },
          unitCost: {
            required: lineItemRequiredValidation,
            decimal
          },
          markupType: {
            inList: value =>
              !helpers.req(value) ||
              contains(value, ["PERCENT", "AMOUNT", "AMOUNT_PER_UNIT", "NONE"])
          },
          markupAmount: {
            decimal,
            required: requiredIf(
              model =>
                !(isNil(model.item) || isEmpty(model.item)) &&
                model.markupType &&
                model.markupType !== "NONE"
            )
          }
        }
      }
    }
  },
  watch: {
    value(newValue) {
      this.changeOrder = clone(newValue);
    }
  },
  methods: {
    saveChangeOrder() {
      this.$v.changeOrder.$touch();
      if (this.$v.changeOrder.$invalid) {
        scrollToError(this);
      } else {
        this.$emit("save", this.changeOrder);
      }
    }
  }
};
</script>
